import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { srConfig } from '@config';
import { KEY_CODES } from '@utils';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledJobsSection = styled.section`
  max-width: 1000px;

  .inner {
    display: flex;

    @media (max-width: 600px) {
      display: block;
    }

    @media (min-width: 700px) {
      min-height: 340px;
    }
  }
`;

const StyledTabList = styled.div`
  position: relative;
  z-index: 3;
  width: max-content;
  padding: 0;
  margin: 0;
  list-style: none;

  @media (max-width: 600px) {
    display: flex;
    overflow-x: auto;
    width: calc(100% + 100px);
    padding-left: 50px;
    margin-left: -50px;
    margin-bottom: 30px;
  }
`;

const StyledTabButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--tab-height);
  padding: 0 20px 2px;
  border-left: 2px solid var(--lightest-navy);
  background-color: transparent;
  color: ${({ isActive }) => (isActive ? 'var(--green)' : 'var(--slate)')};
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
  text-align: left;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: var(--light-navy);
  }
`;

const StyledHighlight = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 2px;
  height: var(--tab-height);
  background: var(--green);
`;

const StyledTabPanels = styled.div`
  position: relative;
  width: 100%;
  margin-left: 30px;
`;

const StyledTabPanel = styled.div`
  width: 100%;
  height: auto;
  padding: 10px 5px;

  h3 {
    margin-bottom: 2px;
    font-size: var(--fz-xxl);
    font-weight: 500;
    line-height: 1.3;

    .company {
      color: var(--green);
    }
  }

  .range {
    margin-bottom: 25px;
    color: var(--light-slate);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
  }
`;

const Jobs = () => {
  const jobs = [
    {
      title: 'Software Developer',
      company: 'Tecnel MC S.à.r.l.',
      range: 'June 2023 - Present',
      description: `
        <ul>
          <li>Developed backend and frontend for home and industry automation hub, managing local and remote servers and integrating databases and APIs</li>
          <li>Led the development of a management software solution that addressed diverse business needs such as invoicing, reimbursements, product management, cart, storage, HR management, and access control.</li>
          <li>Developed a financial software to track revenues and perform deep data analysis, supporting a specific investment algorithm that led to a 23% investment returns over a 4-month period on an initial capital of 100.000 euro.</li>
          <li>Engineered a person detection software capable of identifying obscured faces, improving privacy protection in compliance with GDPR standards. The solution achieved a detection accuracy of 95%</>
        </ul>
      `
    },
    {
      title: 'Insurance Intern',
      company: 'AON PLC',
      range: 'April 2018 - September 2018',
      description: `
        <ul>
          <li>Managed car insurance policies for a portfolio of over 200 customers, ensuring policy compliance and customer satisfaction.</li>
          <li>Administered insurance policies through various portals including Vittoria, UCA, and Nobis, utilizing specialized insurance software to streamline operations.</li>
          <li>Built and maintained strong relationships with insurance brokers, intermediaries, and customers, delivering high-quality service and support.</li>
        </ul>
      `
    },
    {
      title: 'Bank Intern',
      company: 'BCC Banca di Credito Cooperativo di Pianfei',
      range: 'September 2017 - March 2018',
      description: `
        <ul>
          <li>Supervised branch productivity and annual financial reports detailing revenue streams and expenditures for five major bank branches.</li>
          <li>Monitored customer transactions and assessed solvency using Gesbank software, identifying potential risk factors and working with senior staff to implement solutions.</li>
          <li>Participated in internal audits, assisting in the review of branch compliance with industry standards, including lending protocols, privacy regulations, and anti-money laundering policies.</li>
        </ul>
      `
    }
  ];

  const [activeTabId, setActiveTabId] = useState(0);

  return (
    <StyledJobsSection id="jobs">
      <h2 className="numbered-heading">Where I’ve Worked</h2>

      <div className="inner">
        <StyledTabList role="tablist" aria-label="Job tabs">
          {jobs.map((job, i) => (
            <StyledTabButton
              key={i}
              isActive={activeTabId === i}
              onClick={() => setActiveTabId(i)}>
              <span>{job.company}</span>
            </StyledTabButton>
          ))}
          <StyledHighlight activeTabId={activeTabId} />
        </StyledTabList>

        <StyledTabPanels>
          {jobs.map((job, i) => (
            <CSSTransition key={i} in={activeTabId === i} timeout={250} classNames="fade">
              <StyledTabPanel
                role="tabpanel"
                aria-labelledby={`tab-${i}`}
                hidden={activeTabId !== i}>
                <h3>
                  <span>{job.title}</span>
                  <span className="company">
                    &nbsp;@&nbsp;
                    <a href="#">{job.company}</a>
                  </span>
                </h3>
                <p className="range">{job.range}</p>
                <div dangerouslySetInnerHTML={{ __html: job.description }} />
              </StyledTabPanel>
            </CSSTransition>
          ))}
        </StyledTabPanels>
      </div>
    </StyledJobsSection>
  );
};

export default Jobs;

import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="G" transform="translate(33, 23) scale(0.3)">
        <path
          d="M87.7841 71C86.6122 67.4134 85.0675 64.1996 83.1499 61.3587C81.2678 58.4822 79.0128 56.032 76.3849 54.0078C73.7926 51.9837 70.8452 50.4389 67.5426 49.3736C64.2401 48.3082 60.6179 47.7756 56.6761 47.7756C50.2131 47.7756 44.3359 49.4446 39.0447 52.7827C33.7536 56.1207 29.5455 61.0391 26.4205 67.5376C23.2955 74.0362 21.733 82.0085 21.733 91.4545C21.733 100.901 23.3132 108.873 26.4737 115.371C29.6342 121.87 33.9134 126.788 39.3111 130.126C44.7088 133.464 50.7813 135.134 57.5284 135.134C63.7784 135.134 69.2827 133.802 74.0412 131.138C78.8352 128.44 82.5639 124.64 85.2273 119.739C87.9261 114.803 89.2756 108.997 89.2756 102.321L93.3239 103.173H60.5114V91.4545H102.06V103.173C102.06 112.158 100.142 119.97 96.3068 126.611C92.5071 133.251 87.2514 138.401 80.5398 142.058C73.8636 145.68 66.1932 147.491 57.5284 147.491C47.8693 147.491 39.3821 145.219 32.0668 140.673C24.7869 136.128 19.1051 129.665 15.0213 121.284C10.973 112.903 8.94886 102.96 8.94886 91.4545C8.94886 82.8253 10.103 75.066 12.4112 68.1768C14.755 61.2521 18.0575 55.3572 22.3189 50.4922C26.5803 45.6271 31.6229 41.8984 37.4467 39.3061C43.2706 36.7138 49.6804 35.4176 56.6761 35.4176C62.429 35.4176 67.7912 36.2876 72.7628 38.0277C77.7699 39.7322 82.2266 42.1648 86.1328 45.3253C90.0746 48.4503 93.3594 52.1967 95.9872 56.5646C98.6151 60.897 100.426 65.7088 101.42 71H87.7841Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
        L 11, 27
        L 11, 72
        L 50, 95
        L 89, 73
        L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
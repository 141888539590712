import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledCursorEffect = styled.div`
  pointer-events: none;
  position: fixed;
  inset: 0;
  z-index: 30;
  transition: background 0.3s ease;
  background: radial-gradient(600px at 786px 378px, rgba(29, 78, 216, 0.15), transparent 80%);
`;

const CursorEffect = () => {
  const [gradientPosition, setGradientPosition] = useState({ x: 786, y: 378 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setGradientPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <StyledCursorEffect
      style={{
        background: `radial-gradient(600px at ${gradientPosition.x}px ${gradientPosition.y}px, rgba(29, 78, 216, 0.15), transparent 80%)`,
      }}
    />
  );
};

export default CursorEffect;
